.verify-email-container {
  position: absolute; /* or fixed if you want it to stay in place even on scroll */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 100%; /* This ensures that it won't be wider than 400px but can shrink on smaller screens */
  margin: 0 auto; /* Horizontal centering for smaller screens */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 5000; /* Ensure it's above other content */
}
  
  .verify-email-container h2 {
    
    color: #333;
    margin-bottom: 20px;
    z-index: 5000;
  }
  
  .verify-email-container .message {
    
    margin-bottom: 15px;
    padding: 10px;
    background-color: #071bf1;
    color: Black;
    border: 1px solid #071bf1;
    border-radius: 5px;
    z-index: 5000;
  }
  
  .verify-email-container input[type="text"] {
    
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 5000;
  }
  
  .verify-email-container button {
   
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 5000;
  }
  
  .verify-email-container button:hover {
    
    background-color: #0056b3;
    z-index: 5000;
  }