/*NewPassword.css */

.new-password-container{
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 10000;
width: 300px; /* Adjust width as needed */
padding: 20px;
background: white;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;
text-align: center;
z-index: 5000;
}