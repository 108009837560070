/* styles.css */
/*Body */

body {
    margin: 0;
    padding: 0;
    background-color: rgb(75, 73, 73); 
    z-index: 1; 
    
  }*
  /* App.js styles */
  
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 40px;
    position: relative;
    z-index: 1;
  }
  
  
  /* Background.js styles */
  
  .Background-container {
    
    background-image: url('./Pictures/greysimple2.png');
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 
    z-index: 1;
  }
  
  /* Footer.js styles */
  .footer-container {
    background-color: #333;
    padding: 20px;
    box-sizing: border-box; /* Include padding in total height and width */
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px; /* Adjusted height to include padding */
    text-align: center;
    z-index: 1000;
}
  .footer-text {
    margin-bottom: 10px;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  /* Button Container styles */
  .button-container {
    position: fixed;
    top: 87px;
    left: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  
  .expand-button {
    background: none;
    border: 2px solid #000; /* Add a solid border with desired color */
    border-radius: 4px; /* Adjust the border radius as needed */
    padding: 2px; /* Adjust the padding as needed */
    cursor: pointer;
    font-weight: bold;
    font-size: 40px; /* Add this line to set the font size */
    z-index: 0;
  }
  
  .button-wrapper {
    display: none;
    z-index: 1;
  }
  
  .expanded .button-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #626060;
    padding: 10px;
    z-index: 1;
  }
  
  .button {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    color: #030202;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #555;
  }
  
  

z
  /*Chat button*/
  
  
  
  .chat-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    z-index: 2000;
  }
  
  
  
  /* Sign-up modal styles */
  .signup-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4; /* if needed */
  }
  
  /* Sign-up form styles */
  .signup-form-container {
    position: fixed;
    top: 50%; /* position at 50% from the top */
    left: 50%; /* position at 50% from the left */
    transform: translate(-50%, -50%); /* moves the element back by half of its width and height */
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 4;
  }
  
  /* Sign-up form title styles */
  .signup-form-container .signup-title {
    margin: 0;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: left;
  }
  /*password reset*/
  
  .password-reset-container {
    /* Existing styles */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 4;
  }
  .password-reset-close-button {
    /* Styles for the close button */
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    border: none;
    background: none;
    cursor: pointer;
  }
  .password-reset-container h2 {
    /* Styles for the "Forgot Password" heading */
    color: #333; /* Add your desired heading color */
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 15px;
  }
  
  .password-reset-container p {
    /* Styles for the accompanying text */
    color: #333; /* Add your desired text color */
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  



  /*new plan trip css for button from app.js */


  .home-container {
    position: relative;
    z-index: 1000;
}




/* Style for h1 */
.custom-h1 {
  color: rgb(205, 205, 219); /* Change the text color to blue or any color you prefer */
  margin-top: -40px; /* Adjust the top margin to change the vertical position */
  margin-left: -90px; /* Adjust the left margin to change the horizontal position */
  font-size: 40px; /* Adjust the font size as needed */
}

/* Style for p */
.custom-p {
  color: rgb(205, 205, 219); /* Change the text color to green or any color you prefer */
  margin-top: 150px; /* Adjust the top margin to change the vertical position */
  margin-left: -400px; /* Adjust the left margin to change the horizontal position */
  font-size: 15px;
}


