
.button-strip-container {
  position: fixed; /* Fixed position to the viewport */
  left: 0; /* Align to the left side of the viewport */
  top: 0; /* Align to the top of the viewport */
  bottom: 0; /* Stretch to the bottom of the viewport */
  width: 10vw; /* Adjust the width to be 10% of the viewport width */
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the buttons horizontally */
  justify-content: center; /* Center the buttons vertically */
  padding: 2vh 0; /* Vertical padding based on viewport height */
  backdrop-filter: blur(5px); /* Soft blur effect */
  z-index: 2; /* Ensure it's above the background content */
}


.Metroidvania-button {
  position: absolute; /* Or fixed, if button-strip-container is fixed */
  top: 15%; /* Adjust this value to place the button above the button-strip-container */
  left: 50%; /* Center the button above the button-strip-container */
  transform: translateX(-50%); /* Center the button horizontally */
  z-index: 5000; /* Ensure it's above the button-strip-container */
  /* Additional styles... */
  
} 

.Metroidvania-button::before {
  content: ''; /* This is necessary for a pseudo-element */
  position: absolute;
  top: 125%; /* Adjust as needed, negative value to move it below the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%) translateY(10px);  /* Shift it back by half its own width */
  width: 80%; /* Adjust width as needed, less than 100% to not extend too far */
  height: 6px; /* Same as the original border-bottom thickness */
  background-color: rgba(128, 128, 128, 0.4); 
  z-index: 5000;
}


.Metroidvania-icon {
  width: 6vw;              /* Set a fixed width, adjust as needed */
  height: auto;              /* Maintain the icon's aspect ratio */
  transition: transform 0.3s ease; /* For a smooth hover effect */
  z-index: 9999; 
  filter: grayscale(100%);
}

.Metroidvania-button:hover .Metroidvania-icon {
  transform: scale(1.1);     /* Slightly enlarge the icon on hover */
  z-index: 9999; 
}

.Metroidvania-button::after {
  content: attr(title); /* Display the title text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 85%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10001; /* Ensure it's above the icon */
  font-size: 70%; /* Adjust font size as needed */
  opacity: 1; /* Make it always visible */
  pointer-events: none; /* Prevent tooltip from blocking clicks */

}


/* Hover effect for the Profile button */
.Metroidvania-button:hover::after {
  
background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
color: white; /* Text color of the tooltip */
position: absolute;
top: 100%; /* Position it below the button */
left: 50%; /* Center it horizontally */
transform: translateX(-50%); /* Center it horizontally */
padding: 5px 10px; /* Adjust padding as needed */
border-radius: 5px;
white-space: nowrap; /* Prevent line breaks */
z-index: 1; /* Ensure it's above other elements */
font-size: 14px; /* Adjust font size as needed */
opacity: 0; /* Initially hidden */
transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}

.Metroidvania-button:hover::after {
  opacity: 1; /* Show the tooltip on hover */
}


/* Profile Icon Button */
.profile-button {
  position: absolute; /* Or fixed, if button-strip-container is fixed */
  top: 40%; /* Adjust this value to place the button above the button-strip-container */
  left: 50%; /* Center the button above the button-strip-container */
  transform: translateX(-50%); /* Center the button horizontally */
  z-index: 5000; /* Ensure it's above the button-strip-container */
  /* Additional styles... */
}

.profile-icon {
  width: 6vw;              /* Set a fixed width, adjust as needed */
  height: auto;              /* Maintain the icon's aspect ratio */
  transition: transform 0.3s ease; /* For a smooth hover effect */
  z-index: 9999; 
  filter: grayscale(100%);
}
.profile-icon:hover {
  transform: scale(1.1);     /* Slightly enlarge the icon on hover */
  z-index: 9999; 
}

  /* Static tooltip for the Profile button */
.profile-button::after {
  content: attr(title); /* Display the title text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 85%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10001; /* Ensure it's above the icon */
  font-size: 70%; /* Adjust font size as needed */
  opacity: 1; /* Make it always visible */
  pointer-events: none; /* Prevent tooltip from blocking clicks */

}

.profile-button::before {
  content: ''; /* This is necessary for a pseudo-element */
  position: fixed;
  top: 125%; /* Adjust as needed, negative value to move it below the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%) translateY(10px);  /* Shift it back by half its own width */
  width: 80%; /* Adjust width as needed, less than 100% to not extend too far */
  height: 6px; /* Same as the original border-bottom thickness */
  background-color: rgba(128, 128, 128, 0.4); 
  z-index: 5000;
}


/* Hover effect for the Profile button */
.profile-button:hover::after {
  
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 100%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Center it horizontally */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 1; /* Ensure it's above other elements */
  font-size: 14px; /* Adjust font size as needed */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}

.profile-button:hover::after {
  opacity: 1; /* Show the tooltip on hover */
}


/*weather Icon*/

.weather-widget-Button {
  position: absolute;
  top: 65%; /* Adjust as needed */
  left: 50%; /* Start at 50% from the left */
  transform: translateX(-50%); /* Shift it back by half its own width to center it */
  z-index: 5009; /* Adjust if necessary */
}

.weather-widget-icon {
width: 6vw; /* Adjust width as necessary */
height: auto; /* Maintain aspect ratio */
transition: transform 0.3s ease; /* Smooth transition for transform effects */
filter: grayscale(100%);

}

.weather-widget-Button::before {
content: ''; /* This is necessary for a pseudo-element */
  position: fixed;
  top: 125%; /* Adjust as needed, negative value to move it below the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%) translateY(10px);  /* Shift it back by half its own width */
  width: 80%; /* Adjust width as needed, less than 100% to not extend too far */
  height: 6px; /* Same as the original border-bottom thickness */
  background-color: rgba(128, 128, 128, 0.4); 
  z-index: 5000;
}

.weather-widget-Button::after {
content: attr(title); /* Display the title text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 85%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10001; /* Ensure it's above the icon */
  font-size: 70%; /* Adjust font size as needed */
  opacity: 1; /* Make it always visible */
  pointer-events: none; /* Prevent tooltip from blocking clicks */

}

.weather-widget-Button:hover::after {
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 100%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Center it horizontally */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 1; /* Ensure it's above other elements */
  font-size: 14px; /* Adjust font size as needed */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}
.weather-widget-Button:hover::after {
opacity: 1;
}
.weather-widget-Button:hover .weather-widget-icon {
transform: scale(1.1); /* Enlarge the icon on hover */
}


/*set two*/

.Hubspot-button {
  position: absolute; /* Or fixed, if button-strip-container is fixed */
  top: 15%; /* Adjust this value to place the button above the button-strip-container */
  left: 50%; /* Center the button above the button-strip-container */
  transform: translateX(-50%); /* Center the button horizontally */
  z-index: 5000; /* Ensure it's above the button-strip-container */
  /* Additional styles... */
  
} 

.Hubspot-button::before {
  content: ''; /* This is necessary for a pseudo-element */
  position: absolute;
  top: 125%; /* Adjust as needed, negative value to move it below the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%) translateY(10px);  /* Shift it back by half its own width */
  width: 80%; /* Adjust width as needed, less than 100% to not extend too far */
  height: 6px; /* Same as the original border-bottom thickness */
  background-color: rgba(128, 128, 128, 0.4); 
  z-index: 5000;
}


.Hubspot-icon {
  width: 6vw;              /* Set a fixed width, adjust as needed */
  height: auto;              /* Maintain the icon's aspect ratio */
  transition: transform 0.3s ease; /* For a smooth hover effect */
  z-index: 9999; 
  filter: grayscale(100%);
}

.Hubspot-button:hover .Hubspot-icon {
  transform: scale(1.1);     /* Slightly enlarge the icon on hover */
  z-index: 9999; 
}

.Hubspot-button::after {
  content: attr(title); /* Display the title text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 85%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10001; /* Ensure it's above the icon */
  font-size: 70%; /* Adjust font size as needed */
  opacity: 1; /* Make it always visible */
  pointer-events: none; /* Prevent tooltip from blocking clicks */

}


/* Hover effect for the Profile button */
.Hubspot-button:hover::after {
  
background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
color: white; /* Text color of the tooltip */
position: absolute;
top: 100%; /* Position it below the button */
left: 50%; /* Center it horizontally */
transform: translateX(-50%); /* Center it horizontally */
padding: 5px 10px; /* Adjust padding as needed */
border-radius: 5px;
white-space: nowrap; /* Prevent line breaks */
z-index: 1; /* Ensure it's above other elements */
font-size: 14px; /* Adjust font size as needed */
opacity: 0; /* Initially hidden */
transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}

.Hubspot-button:hover::after {
  opacity: 1; /* Show the tooltip on hover */
}

.experience-button {
  position: absolute; /* Or fixed, if button-strip-container is fixed */
  top: 40%; /* Adjust this value to place the button above the button-strip-container */
  left: 50%; /* Center the button above the button-strip-container */
  transform: translateX(-50%); /* Center the button horizontally */
  z-index: 5000; /* Ensure it's above the button-strip-container */
  /* Additional styles... */
  
} 

.experience-button::before {
  content: ''; /* This is necessary for a pseudo-element */
  position: absolute;
  top: 125%; /* Adjust as needed, negative value to move it below the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%) translateY(10px);  /* Shift it back by half its own width */
  width: 80%; /* Adjust width as needed, less than 100% to not extend too far */
  height: 6px; /* Same as the original border-bottom thickness */
  background-color: rgba(128, 128, 128, 0.4); 
  z-index: 5000;
}


.experience-icon {
  width: 6vw;              /* Set a fixed width, adjust as needed */
  height: auto;              /* Maintain the icon's aspect ratio */
  transition: transform 0.3s ease; /* For a smooth hover effect */
  z-index: 9999; 
  filter: grayscale(100%);
}

.experience-icon:hover {
  transform: scale(1.1);     /* Slightly enlarge the icon on hover */
  z-index: 9999; 
}

.experience-button::after {
  content: attr(title); /* Display the title text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 85%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10001; /* Ensure it's above the icon */
  font-size: 70%; /* Adjust font size as needed */
  opacity: 1; /* Make it always visible */
  pointer-events: none; /* Prevent tooltip from blocking clicks */

}


/* Hover effect for the Profile button */
.experience-button:hover::after {
  
background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
color: white; /* Text color of the tooltip */
position: absolute;
top: 100%; /* Position it below the button */
left: 50%; /* Center it horizontally */
transform: translateX(-50%); /* Center it horizontally */
padding: 5px 10px; /* Adjust padding as needed */
border-radius: 5px;
white-space: nowrap; /* Prevent line breaks */
z-index: 1; /* Ensure it's above other elements */
font-size: 14px; /* Adjust font size as needed */
opacity: 0; /* Initially hidden */
transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}

.experience-button:hover::after {
  opacity: 1; /* Show the tooltip on hover */
}


.about-button {
  position: absolute;
  top: 65%; /* Adjust as needed */
  left: 50%; /* Start at 50% from the left */
  transform: translateX(-50%); /* Shift it back by half its own width to center it */
  z-index: 5009; /* Adjust if necessary */
}

.about-icon {
width: 6vw; /* Adjust width as necessary */
height: auto; /* Maintain aspect ratio */
transition: transform 0.3s ease; /* Smooth transition for transform effects */
filter: grayscale(100%);

}
.about-icon:hover {
  transform: scale(1.1);     /* Slightly enlarge the icon on hover */
  z-index: 9999; 
}

.about-button::before {
content: ''; /* This is necessary for a pseudo-element */
  position: fixed;
  top: 125%; /* Adjust as needed, negative value to move it below the button */
  left: 50%; /* Start from the middle of the button */
  transform: translateX(-50%) translateY(10px);  /* Shift it back by half its own width */
  width: 80%; /* Adjust width as needed, less than 100% to not extend too far */
  height: 6px; /* Same as the original border-bottom thickness */
  background-color: rgba(128, 128, 128, 0.4); 
  z-index: 5000;
}

.about-button::after {
content: attr(title); /* Display the title text */
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 85%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10001; /* Ensure it's above the icon */
  font-size: 70%; /* Adjust font size as needed */
  opacity: 1; /* Make it always visible */
  pointer-events: none; /* Prevent tooltip from blocking clicks */

}

.about-button:hover::after {
  background-color: rgba(0, 0, 0, 0.8); /* Background color of the tooltip */
  color: white; /* Text color of the tooltip */
  position: absolute;
  top: 100%; /* Position it below the button */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Center it horizontally */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  white-space: nowrap; /* Prevent line breaks */
  z-index: 1; /* Ensure it's above other elements */
  font-size: 14px; /* Adjust font size as needed */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out effect */
}
.about-button:hover::after {
opacity: 1;
}
.weather-widget-Button:hover .weather-widget-icon {
transform: scale(1.1); /* Enlarge the icon on hover */
}


/*toggle button*/

.toggle-button {
  position: absolute; /* Or fixed, if button-strip-container is fixed */
  top: 84%; /* Adjust this value to place the button above the button-strip-container */
  left: 50%; /* Center the button above the button-strip-container */
  transform: translateX(-50%); /* Center the button horizontally */
  z-index: 5000; /* Ensure it's above the button-strip-container */
  background-color: white; /* Turquoise with 50% transparency */
  border: none; /* Removes the border, add 'border: 1px solid rgba(64, 224, 208, 0.5);' if you want a border */
  color: black; /* Text color, change as needed */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change mouse cursor to pointer when hovering */
  /* Additional styles like font-size, font-weight, etc. */
  
}
.toggle-button:hover {
  background-color: black; /* Background color changes to black on hover */
  color: white; /* Text color changes to white on hover */
}
@media (max-width:1400px) and (max-height: 1100px){
.button-strip-container {
  width: 15vw; /* Adjust width as needed */
}

.Metroidvania-icon {
  width: 10vw; /* Adjust width as needed */
}

.profile-icon {
  width: 10vw; /* Adjust width as needed */
}

.weather-widget-icon {
  width: 10vw; /* Adjust width as needed */
}

.Hubspot-icon {
  width: 10vw;

}
.experience-icon {
  width: 10vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 10vw; /* Adjust width as necessary */
  
  
  }

/* Add other styles as needed */
}

@media (max-width:1300px) and (max-height: 950px){
.button-strip-container {
  width: 15vw; /* Adjust width as needed */
}

.Metroidvania-icon {
  width: 7.5vw; /* Adjust width as needed */
}



.profile-icon {
  width: 7.5vw; /* Adjust width as needed */
}


.weather-widget-icon {
  width: 7.5vw; /* Adjust width as needed */
}
.Hubspot-icon {
  width: 7.5vw;

}

.experience-icon {
  width: 7.5vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 7.5vw; /* Adjust width as necessary */
  
  
  }

.toggle-button {
  top: 83.5%;

}

/* Add other styles as needed */
}
@media (max-width:1100px) and (max-height: 1400px){
.button-strip-container {
  width: 15vw; /* Adjust width as needed */
}

.Metroidvania-icon {
  width: 10vw; /* Adjust width as needed */
}



.profile-icon {
  width: 10vw; /* Adjust width as needed */
}


.weather-widget-icon {
  width: 10vw; /* Adjust width as needed */
}

.Hubspot-icon {
  width: 10vw;

}

.experience-icon {
  width: 10vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 10vw; /* Adjust width as necessary */
  
  
  }


/* Add other styles as needed */
}

@media (max-width:1100px) and (max-height: 680px){
.button-strip-container {
  width: 15vw; /* Adjust width as needed */
}

.Metroidvania-icon {
  width: 5vw; /* Adjust width as needed */
}



.profile-icon {
  width: 5vw; /* Adjust width as needed */
}


.weather-widget-icon {
  width: 5vw; /* Adjust width as needed */
}

.Hubspot-icon {
  width: 5vw;

}

.experience-icon {
  width: 5vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 5vw; /* Adjust width as necessary */
  
  
  }

.toggle-button {
  top: 79%;

}

/* Add other styles as needed */
}


@media (max-width:800px) and (max-height: 550px){
  .button-strip-container {
    width: 15vw; /* Adjust width as needed */
  }
  
  .Metroidvania-icon {
    width: 5vw; /* Adjust width as needed */
  }
  
  
  
  .profile-icon {
    width: 5vw; /* Adjust width as needed */
  }
  
  
  .weather-widget-icon {
    width: 5vw; /* Adjust width as needed */
  }
  
  .Hubspot-icon {
    width: 8vw;
  
  }

  .experience-icon {
    width: 8vw;              /* Set a fixed width, adjust as needed */
    
  }

  .about-icon {
    width: 8vw; /* Adjust width as necessary */
    
    
    }
  
  .toggle-button {
    top: 79%;
  
  }
  
  /* Add other styles as needed */
  }

@media (max-width: 480px) {
.button-strip-container {
    width: 20vw; /* Even bigger for very small screens */
     /* A comfortable maximum width for very small screens */
}

.Metroidvania-icon{
  
    width: 18vw; /* Even bigger for very small screens */
     /* A comfortable maximum width for very small screens */
}

.profile-icon{
    width: 18vw; /* Even bigger for very small screens */
     /* A comfortable maximum width for very small screens */
}


.weather-widget-icon {
    width: 18vw; /* Even bigger for very small screens */
     /* A comfortable maximum width for very small screens */
}

.Hubspot-icon {
  width: 18vw;

}

.experience-icon {
  width: 18vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 18vw; /* Adjust width as necessary */
  
  
  }
.toggle-button {
  top: 81.5%;

}


}

@media (max-width: 376px) {
.button-strip-container {
  width: 20vw; /* Even bigger for very small screens */
   /* A comfortable maximum width for very small screens */
}

.Metroidvania-icon{

  width: 19vw; /* Even bigger for very small screens */
   /* A comfortable maximum width for very small screens */
}
.profile-icon{
  width: 19vw; /* Even bigger for very small screens */
   /* A comfortable maximum width for very small screens */
}

.weather-widget-icon {
  width: 19vw; /* Even bigger for very small screens */
   /* A comfortable maximum width for very small screens */
}

.Hubspot-icon {
  width: 19vw;

}

.experience-icon {
  width: 19vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 19vw; /* Adjust width as necessary */
  
  
  }
}

@media (max-width: 321px) {
.button-strip-container {
width: 20vw; /* Even bigger for very small screens */
 /* A comfortable maximum width for very small screens */
}

.Metroidvania-icon{

width: 18vw; /* Even bigger for very small screens */
 /* A comfortable maximum width for very small screens */
}

.Metroidvania-button::after {
font-size: 7.5px;

}


.profile-icon{
width: 18vw; /* Even bigger for very small screens */
 /* A comfortable maximum width for very small screens */
}

.profile-button::after {
font-size: 7.5px;

}


.weather-widget-icon {
width: 18vw; /* Even bigger for very small screens */
 /* A comfortable maximum width for very small screens */
}

.weather-widget-Button::after {
font-size: 7.5px;

}

.Hubspot-icon {
  width: 18vw;

}

.experience-icon {
  width: 18vw;              /* Set a fixed width, adjust as needed */
  
}

.about-icon {
  width: 18vw; /* Adjust width as necessary */
  
  
  }
.toggle-button {
  top: 79.5%;

}
}


.home-container {
  position: relative;
  z-index: 1000;
}

.welcome-sub {

  position: fixed;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2C3E50;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 20px;
   
  text-align: center;
  z-index: 1001;
}

.disclaimer{
position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer; 
  text-align: center;
  z-index: 1001;
}

.welcome-message {
  position: fixed;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2C3E50;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)); /* More transparent */
  font-weight: bold;
  font-size: 40px;
  text-align: center; 
  z-index: 1001;
  /* Adjust margin and background as needed */
}

.info-btn, .info-btn:focus {
  position: fixed;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2C3E50;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.7));
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 1002;
  /* Add padding, width, or other styles as needed */
}

.info-btn:hover, .info-btn:focus:hover {
  background: #333333; /* Dark grey background on hover */
  
  color: white; /* White text color on hover */
  text-shadow: none; /* Optional: remove text shadow on hover for better readability */
}

.sub-message {
  position: fixed;
  top: 63.5%; 
  left: 51%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #34495E;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9)); /* Less transparent background */
  text-align: center;
  margin-top: 20px;
  z-index: 1001;
  /* Adjust margin, padding, width and background as needed */
}

.chat-button {
  position: fixed;
  bottom: 9.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
  right: 3%; /* Adjust this value to keep the button a certain distance from the right */
  
  
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  
  align-items: center;
  
  font-size: 50px;
  
  z-index: 10; /* Increase the z-index value */
}

.chat-button:hover {
  background-color: #555;
}


@media (max-width: 800px) {
  
  .welcome-sub {

    position: fixed;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer; 
    text-align: center;
    z-index: 1001;
}

.disclaimer{
  position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer; 
    text-align: center;
    z-index: 1001;
}

.welcome-message {
    position: fixed;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)); /* More transparent */
    font-weight: bold;
    font-size: 40px;
    text-align: center; 
    z-index: 1001;
    /* Adjust margin and background as needed */
}

.info-btn, .info-btn:focus {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
   
    border: none;
    font-size: 18px;
    
    cursor: pointer;
    z-index: 1002;
    /* Add padding, width, or other styles as needed */
}

.sub-message {
    position: fixed;
    top: 58.5%; 
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #34495E;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    
    text-align: center;
    margin-top: 20px;
    
    z-index: 1001;
    /* Adjust margin, padding, width and background as needed */
}
}
@media (max-width: 800px) and (max-height: 580px) {
  
  .welcome-sub {

    position: fixed;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer; 
    text-align: center;
    z-index: 1001;
}

.disclaimer{
  position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    background: transparent;
    border: none;
    font-size: 12px;
    cursor: pointer; 
    text-align: center;
    z-index: 1001;
}

.welcome-message {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)); /* More transparent */
    font-weight: bold;
    font-size: 30px;
    text-align: center; 
    z-index: 1001;
    /* Adjust margin and background as needed */
}

.info-btn, .info-btn:focus {
    position: fixed;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    
    border: none;
    font-size: 16px;
    
    cursor: pointer;
    z-index: 1002;
    /* Add padding, width, or other styles as needed */
}

.sub-message {
    position: fixed;
    top: 70.5%; 
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #34495E;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    
    text-align: center;
    
    
    z-index: 1001;
    /* Adjust margin, padding, width and background as needed */
}
}

@media (max-width: 1000px) and (max-height: 500px) {
  
  .welcome-sub {

    position: fixed;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer; 
    text-align: center;
    z-index: 1001;
}

.disclaimer{
  position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    background: transparent;
    border: none;
    font-size: 12px;
    cursor: pointer; 
    text-align: center;
    z-index: 1001;
}

.welcome-message {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
    
    font-size: 30px;
    text-align: center; 
    z-index: 1001;
    /* Adjust margin and background as needed */
}

.info-btn, .info-btn:focus {
    position: fixed;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2C3E50;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    
    border: none;
    font-size: 16px;
    
    cursor: pointer;
    z-index: 1002;
    /* Add padding, width, or other styles as needed */
}

.sub-message {
    position: fixed;
    top: 70.5%; 
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #34495E;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    
    text-align: center;
    
    
    z-index: 1001;
    /* Adjust margin, padding, width and background as needed */
}
}

@media (max-width: 560px) {
  
/* Adjust the .welcome-message and .sub-message accordingly */
.welcome-message{
  /* ... */
  position: fixed;
  top: 24%;
  left: 50%;
  font-size: 40px;
  transform: translateX(-50%);
  /* Position top for .welcome-message and .sub-message as needed */
}
.welcome-sub {
  position: fixed;
  top: 42%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 20px;
}
.info-btn, .info-btn:focus{
  /* ... */
  position: fixed;
  top: 52%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  /* Position top for .welcome-message and .sub-message as needed */
}
.sub-message {
  /* ... */
  top:58%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  /* Position top for .welcome-message and .sub-message as needed */
}

.disclaimer{
  position: fixed;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 10px;
  cursor: pointer; 
  z-index: 1001;
}
.chat-button {
  position: fixed;
  bottom: 11.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
  right: 3%; /* Adjust this value to keep the button a certain distance from the right */
  
  
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  
  align-items: center;
  
  font-size: 50px;
  
  z-index: 1000; /* Increase the z-index value */
}
}

@media (max-width: 450px) and (max-height: 1000px){
  
/* Adjust the .welcome-message and .sub-message accordingly */
.welcome-message{
  /* ... */
  position: fixed;
  top: 26%;
  left: 50%;
  font-size: 40px;
  transform: translateX(-50%);
  font-size: 40px;
  /* Position top for .welcome-message and .sub-message as needed */
}
.welcome-sub {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 18px;
}
.info-btn, .info-btn:focus{
  /* ... */
  position: fixed;
  top: 48%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  /* Position top for .welcome-message and .sub-message as needed */
}
.sub-message {
  /* ... */
  top:58%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 45svw;
  /* Position top for .welcome-message and .sub-message as needed */
}

.disclaimer{
  position: fixed;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer; 
  z-index: 1001;
}
.chat-button {
  position: fixed;
  bottom: 11.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
  right: 3%; /* Adjust this value to keep the button a certain distance from the right */
  
  
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  
  align-items: center;
  
  font-size: 50px;
  
  z-index: 1000; /* Increase the z-index value */
}
}

@media (max-width: 450px) and (max-height: 920px){
  
/* Adjust the .welcome-message and .sub-message accordingly */
.welcome-message{
  /* ... */
  position: fixed;
  top: 26%;
  left: 50%;
  font-size: 40px;
  transform: translateX(-50%);
  /* Position top for .welcome-message and .sub-message as needed */
}
.welcome-sub {
  position: fixed;
  top: 42%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 18px;
}
.info-btn, .info-btn:focus{
  /* ... */
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  /* Position top for .welcome-message and .sub-message as needed */
}
.sub-message {
  /* ... */
  top:58%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 45svw;
  /* Position top for .welcome-message and .sub-message as needed */
}

.disclaimer{
  position: fixed;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer; 
  z-index: 1001;
}
.chat-button {
  position: fixed;
  bottom: 11.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
  right: 3%; /* Adjust this value to keep the button a certain distance from the right */
  
  
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  
  align-items: center;
  
  font-size: 50px;
  
  z-index: 1000; /* Increase the z-index value */
}
}



@media (max-width: 400px) and (max-height: 850px){

/* Adjust the .welcome-message and .sub-message accordingly */
.welcome-message{
  /* ... */
  position: fixed;
  top: 26%;
  left: 50%;
  font-size: 40px;
  transform: translateX(-50%);
  /* Position top for .welcome-message and .sub-message as needed */
}
.welcome-sub {
  position: fixed;
  top: 44%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 16px;
}
.info-btn, .info-btn:focus{
  /* ... */
  position: fixed;
  top: 52%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  /* Position top for .welcome-message and .sub-message as needed */
}
.sub-message {
  /* ... */
  top:58%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 45svw;
  /* Position top for .welcome-message and .sub-message as needed */
}

.disclaimer{
  position: fixed;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer; 
  z-index: 1001;
}
.chat-button {
  position: fixed;
  bottom: 11.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
  right: 3%; /* Adjust this value to keep the button a certain distance from the right */
  
  
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  
  align-items: center;
  
  font-size: 50px;
  
  z-index: 1000; /* Increase the z-index value */
}
}

@media (max-width: 400px) and (max-height: 700px){

/* Adjust the .welcome-message and .sub-message accordingly */
.welcome-message{
/* ... */
position: fixed;
top: 24%;
left: 50%;
font-size: 35px;
transform: translateX(-50%);
font-size: 35px;
/* Position top for .welcome-message and .sub-message as needed */
}
.welcome-sub {
position: fixed;
top: 44%;
left: 50%;
width: 50%;
transform: translateX(-50%);
font-size: 15px;

}
.info-btn, .info-btn:focus{
/* ... */
position: fixed;
top: 52%;
left: 50%;
width: 50%;
transform: translateX(-50%);
/* Position top for .welcome-message and .sub-message as needed */
font-size: 14px;
}
.sub-message {
/* ... */
top:58%;
position: fixed;
left: 50%;
transform: translateX(-50%);
width: 45svw;
font-size: 14px;
/* Position top for .welcome-message and .sub-message as needed */
}

.disclaimer{
position: fixed;
top: 17%;
left: 50%;
transform: translate(-50%, -50%);
color: red;
font-weight: bold;
text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
background: transparent;
border: none;
font-size: 10px;
cursor: pointer; 
z-index: 1001;
}

.chat-button {
position: fixed;
bottom: 12.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
right: 3%; /* Adjust this value to keep the button a certain distance from the right */


background-color: #333;
color: #fff;
border-radius: 50%;

align-items: center;

font-size: 50px;

z-index: 1000; /* Increase the z-index value */
}
}

@media (max-width: 320px){

/* Adjust the .welcome-message and .sub-message accordingly */
.welcome-message{
  /* ... */
  position: fixed;
  top: 24%;
  left: 50%;
  font-size: 35px;
  transform: translateX(-50%);
  font-size: 30px;
  /* Position top for .welcome-message and .sub-message as needed */
}
.welcome-sub {
  position: fixed;
  top: 42%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  
}
.info-btn, .info-btn:focus{
  /* ... */
  position: fixed;
  top: 52%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  /* Position top for .welcome-message and .sub-message as needed */
  font-size: 12px;
}
.sub-message {
  /* ... */
  top:58%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 45svw;
  font-size: 12px;
  /* Position top for .welcome-message and .sub-message as needed */
}

.disclaimer{
  position: fixed;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  font-size: 8px;
  cursor: pointer; 
  z-index: 1001;
}

.chat-button {
  position: fixed;
  bottom: 12.5vh; /* Adjust this value to keep the button a certain distance from the bottom */
  right: 3%; /* Adjust this value to keep the button a certain distance from the right */
  
  
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  
  align-items: center;
  
  font-size: 40px;
  
  z-index: 1000; /* Increase the z-index value */
}
}