/* Container styling */
.profile-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000; /* Only one z-index value is necessary */

}

.profile-update-container {
    background-color: white; /* Switch to a light, neutral color for contrast */
    padding: 2rem; /* More padding for a spacious feel */
    border-radius: 1rem; /* Rounded corners are visually appealing */
    max-width: 35rem; /* Adjust the max width for desktop */
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2); /* Softer shadow for depth */
    position: relative;
    text-align: center;
    overflow: hidden; /* Ensure nothing spills out */
}

/* Close button styling */
.profileClose {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff6666; /* Red */
    color: #ffffff;
    border: none;
    border-radius: 50%; /* Circle shape */
    width: 30px;
    height: 30px;
    cursor: pointer;
}

/* Header styling */
.profile-update-container h2 {
    margin: 0; /* Reset margin */
    padding-bottom: 1rem; /* Space from the content below */
    font-size: 2rem; /* Larger, more prominent title */
    font-weight: bold; /* Emphasize the title */
    color: #333; /* Dark color for readability */
    border-bottom: 30px solid #eaeaea; /* A subtle separator */
    
}

/* Profile picture section */
.profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-picture img {
    width: 50%; /* Use percentage for responsiveness */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%; /* Circular image */
    max-width: 120px; /* Maximum size */
    margin-bottom: 10px;
}

.bio {
    margin-top: 1.5rem; /* Space from the image */
}

/* Textarea styling */
.bio textarea {
    width: calc(100% - 20px); /* Adjust width for padding */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    resize: vertical; /* Allows the textarea to be resized only vertically */
}

.bio label {
    display: block; /* Ensure it's on its own line */
    margin-bottom: 0.5rem; /* Space below label */
    color: #666; /* Lighter color for the label */
    font-size: 1rem; /* Appropriate font size */
    font-weight: normal; /* Regular weight to not overpower */
}

.bio textarea {
    width: 100%; /* Full width */
    padding: 1rem; /* Comfortable padding inside the textarea */
    border-radius: 0.5rem; /* Consistent rounded corners */
    border: 1px solid #eaeaea; /* Light border */
    resize: none; /* Disallow resizing to maintain layout integrity */
    height: 6rem; /* Enough space to write a small paragraph */
}
/* Button styling */
button {
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.SaveButtonProfile {
    width: 100%;
    padding: 10px 0; /* More vertical padding for touch targets */
    background-color: rgb(98, 98, 233); 
}

.SaveButtonProfile:hover {
    background-color: rgb(6, 6, 80); 

}
.SaveButtonProfile :hover {
    background-color: rgb(6, 6, 80); 

}
.profile-update-container{
    /* ... [existing styles] */
    text-align: center;     /* Center align the text */
    
}
.profile-update-container label {
    display: block;         /* Display as block to take full width and ensure it's on its own line */
    margin-bottom: 10px;    /* Space below the label */
    font-weight: bold;      /* Make the text bold */
    font-size: 20px;        /* Set font size */
    color: #333;            /* Set text color */
    text-align: center;
    
}
.navigate-prev, .navigate-next{
    width: 100%;
    padding: 10px 0; /* More vertical padding for touch targets */
    background-color: rgb(98, 98, 233); 

}

.navigate-prev:hover, .navigate-next:hover{

    background-color: rgb(6, 6, 80); 
}


.closeButton {
    position: absolute;
    top: 0rem;
    right: 1rem;
    color:black;
    background-color: transparent;
    border: none;
    font-size: 2rem; /* Larger click area */
    line-height: 1; /* Remove extra height */
    cursor: pointer;
    transition: transform 0.2s; /* Smooth transform transition */
}

.closeButton:hover {
    color: #668aff;                 /* Change color on hover */
    background: none;
 
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profile-update-container {
        width: 80%; /* Slightly increase width for tablet devices */
    }

    h2, .bio textarea, button {
        font-size: 16px; /* Adjust font size for smaller devices */
    }
}

@media (max-width: 480px) {
    .profile-update-container {
        width: 90%; /* Full width on mobile for better use of space */
    }

    h2, .bio textarea, button {
        font-size: 14px; /* Smaller font size for small devices */
    }

    .profileClose, .closeButton {
        font-size: 1.2rem; /* Adjust close button size for mobile */
    }
}

/* Adjustments for Galaxy Fold and similar ratios */
@media (min-width: 270px) and (max-width: 280px) {
    .profile-update-container {
        /* Adjust width, padding, or other properties as needed for this screen size */
        width: 100%;
        padding: 10px;
    }

    .profile-picture img {
        /* Adjust the size of the profile image specifically for this view */
        width: 100px; /* Example size, adjust as needed */
        height: 100px; /* Maintain aspect ratio */
        object-fit: cover; /* Cover the area, cropping if necessary */
        border-radius: 50%;
        margin: 0 auto; /* Center the image in the container */
    }

    /* You may want to adjust other elements as well to fit the Galaxy Fold's unique ratio */
}