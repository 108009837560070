.cert-container {
    position: fixed;
    top: 18%;
    left: 0;
    right: 0;
    width: 75%;
    height: auto;
    padding: 15px;
    background-color: rgba(241, 241, 241, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 5000;
    overflow-y: auto;
    margin: auto;
}

.cert-title {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    color: #007bff;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: black;
    z-index: 5001;
}

.cert-image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 20px auto; /* Adjust margin to ensure proper spacing */
}

.cert-description {
    text-align: center;
    padding: 20px;
    margin-bottom: 20px; /* Additional margin for better spacing */
}

