/*border: 1px solid red;*/
/* Ensure elements don't exceed their assigned borders */
*,
*::before,
*::after {
  box-sizing: border-box;
}
.chat-container {
  position: fixed;
  margin: auto;
  top: 14%;
  right:0;
  left:0;
  width: 90%; /* Adjust as necessary */
  height: 73%; /* Adjust as necessary */
  padding: 15px;
  background-color: rgba(241, 241, 241, 0.7);  /* Updated this line */
  border-radius: 10px;
  overflow: hidden;
  z-index: 5000;
  overflow-y: auto;
  
}
.chat-title {
  font-weight: bold;
  
}

.chat-title h1 {
  text-align: center;
  font-size: 2em;
  color: #333;
  margin-bottom: 20px; /* Add space below the title */
  
}

.delete-chat-button {
  background-color: #ff4d4d; /* Red background for the delete button */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners for the button */

}
.delete-chat-button:hover {
  background-color: #ff0000; /* Darker red on hover */
}

.chat-list-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Take full height of the chat-container */
  
}

.chat-list-button, .create-chat-button, .new-chat-form-button, button {
  padding: 8px 15px;
  margin: 10px 0;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  

}

.info-button-container {
  position: fixed;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 10; /* Adjust if necessary to ensure it's above other elements */
}

.portfolio-btn2 {
  position: sticky;
  top: 0%; /* Adjust as necessary */
  left: 100%; /* Adjust as necessary */
  padding: 8px 15px;
  background-color: transparent; /* Blue background */
  color: rgb(2, 2, 2); /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Clickable cursor style */
  z-index: 5; /* Ensure it's above other elements */
  border: 1px solid rgb(2, 2, 2);
  
}
.portfolio:hover {
  background-color: #0056b3; /* A darker blue for hover state */
}

.chat-list-button:hover, .create-chat-button:hover, .new-chat-form-button:hover, button:hover {
  background-color: #0069d9; ;

}

.sub-message2 {
  background-color: rgba(241, 241, 241, 0.7); /* Background with slight transparency */
  padding: 20px; /* Inner spacing */
  border-radius: 10px; /* Rounded corners */
  margin: 20px 0; /* Space above and below the div */
  font-size: 1em; /* Text size */
  line-height: 1.5; /* Space between lines of text */

}

.chat-list-modal {
  display: none;
  flex-grow: 1; /* Allows this element to grow and take up any available space */
  overflow-y: auto; /* Keeps it scrollable */
  
}

.chat-list-modal.expanded {
  display: block;
  
}

.chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd; /* Light grey border */
  padding: 8px 12px;
  margin-bottom: 8px; /* Space between chat entries */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
  
  background: rgba(249, 249, 249, 0.9);
  
}
.chat-info {
  flex-grow: 1;
  cursor: pointer;
}


.chat:hover {
  background-color: #0069d9; /* or any color you prefer */
  box-shadow: 0 4px 8px rgba(0,0,0,0.15); /* More pronounced shadow on hover */
  cursor: pointer; /* Change cursor to indicate clickable items */

}

.chat-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover; 
  margin-right: 10px;

}

.message {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background: rgba(249, 249, 249, 0.9);
  
  word-break: break-word; /* This will break long unbroken text to the next line */
  overflow-wrap: break-word; /* This is the standard property for breaking words */
  /* Rest of your styling */
  
}



.message p {
  display: inline-block; /* Display paragraph inline with the image */
  margin: 0; /* Remove default margin from paragraph */
  vertical-align: middle; /* Align the text vertically with the image */
  margin-right: 80px; /* Adjusts based on delete button size */

}

.delete-message-button {
  position: absolute;
  top: 35%;
  right: 10px;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.delete-message-button:hover {
  background-color: #ff7875;
}

.delete-message-button:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.message-profile-pic {
  width: 40px; /* Set a fixed width for profile images */
  height: 40px; /* Set a fixed height for profile images */
  border-radius: 50%; /* Circular images */
  object-fit: cover; /* Ensure the image covers the area without stretching */
  margin-right: 10px; /* Add some space between image and text */
  vertical-align: middle; /* Align the image vertically with the text */
}

.create-chat-button-container {
    
    padding: 10px;
    /* Removed the sticky positioning, as it should naturally stay at the bottom due to flex layout */
}
.new-chat-form-container {
  display: flex;
  align-items: center;
  background-color: white; /* Background color for the entire container */
  padding: 20px;
  border-radius: 10px; /* Match this to the input and button for uniformity */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
  
}

input[type="text"] {
  flex: 1; /* allows the input to grow and fill the space */
  margin-right: 10px; /* gives some space between the input and the button */
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Adjust the color as needed */
  background-color: rgba(241, 241, 241, 0.7); /* Adjust the color and opacity as needed */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */

}


/* Style for other parts of your chat UI */
.chat-name {
  font-size: 1.8em;
  margin-bottom: 10px;

}

.close-button {
  position: sticky;
  top: 0;
  right: 100%; /* Stick to the right side */
  margin-left: auto; /* This will help push the button to the far right */
  background-color: transparent;
  border: 1px solid rgb(2, 2, 2);
  font-size: 1.2em;
  cursor: pointer;
  color: black;
  z-index: 5000;
}
.close-button:hover {
  background-color: #0069d9;
}
.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures spacing between the input and the button */
  background-color: white; /* Adjust to match your theme */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 10px 0; /* Provides space around the container */

}

.input-container input {
  flex: 1; /* allows the input to grow and fill the space */
  margin-right: 10px; /* gives some space between the input and the button */
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Adjust the color as needed */
  background-color: rgba(241, 241, 241, 0.7); /* Adjust the color and opacity as needed */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */
  
}

.input-container input:focus {
  border-color: #a0a0a0; /* Darker border color on focus */

}

.input-container button {
  padding: 10px 20px;
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap; /* prevents the text inside the button from wrapping */

}
.input-container button:hover {
  background-color: #0069d9; /* Darker shade for hover state */

}

.input-container button:active {
  background-color: #0056b3; /* Even darker for active/click state */

}


.add-user-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures spacing between the input and the button */
  background-color: white; /* Adjust to match your theme */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 10px 0; /* Provides space around the container */

}

.add-user-container input {
  flex: 1; /* allows the input to grow and fill the space */
  margin-right: 10px; /* gives some space between the input and the button */
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Adjust the color as needed */
  background-color: rgba(241, 241, 241, 0.7); /* Adjust the color and opacity as needed */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */

}

.add-user-container button {
  padding: 10px 20px;
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
}

.add-user-container button:hover {
  background-color: #0069d9; /* Darker shade for hover state */

}

.add-user-container button:active {
  background-color: #0056b3; /* Even darker for active/click state */
  
}

/* You may want to adjust the styles for responsiveness */
@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
    padding: 15px; /* More padding for a stacked layout on smaller screens */
    
  }

  .input-container input{
    margin-right: 10px; /* Remove the margin between input and button */
    
  }
  .input-container button {
    margin-right: 0; /* Remove the margin between input and button */
    margin-bottom: 10px; /* Add some space between stacked items */
    width: 100%; /* Full width for small screens */
  
  }

  .input-container button {
    padding: 12px 20px; /* Slightly larger button for easier interaction on touch devices */
  
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .add-user-container {
    flex-direction: column;
    padding: 15px; /* More padding for a stacked layout on smaller screens */
    
  }

  .add-user-container input{

    margin-right: 10px; /* Remove the margin between input and button */
    
  }
  .add-user-container button {
    margin-right: 0; /* Remove margin-right since stacking will occur */
    margin-bottom: 10px; /* Add margin-bottom for spacing */
    width: 100%; /* Full width for smaller screens */
    
  }

  .add-user-container button {
    padding: 12px 20px; /* Slightly larger padding for easier interaction */
    
   
  }

  /* Reset the margin-bottom for the last button to avoid extra spacing */
  .add-user-container button:last-child {
    margin-bottom: 0;
  }
}