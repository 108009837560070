/* Existing body styles */
body {
    padding-top: 80px; /* Height of the header */
    padding-bottom: 70px; /* Height of the footer */
    margin: 0; /* Reset default margin */
  }
  
  /* Updated contact-container styles */
  .contact-container {
    flex-direction: column; /* Aligns children vertically */
    align-items: center; /* Centers children horizontally */
    justify-content: center; /* Centers children vertically if the container is taller than the content */
    text-align: center; /* Center-aligns text for all child elements */
    width: 90%; /* Narrower width can help reduce the overall height */
    height: 80vh;
    overflow-y: auto;
    max-width: 500px; /* Reduced maximum width */
    margin: 1rem auto; /* Centering in the viewport */
    padding: 1rem; /* Padding inside the container */
    background: #fff; /* Background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    position: relative; /* For positioning context */
    z-index: 1; /* Below the header and footer */
    padding-top: 20px; /* Reduced additional space at the top */
    padding-bottom: 70px; /* Reduced additional space at the bottom */
  }

  
.contact-heading,
.contact-subheading {
text-align: center; /* Center-align the text for both the heading and subheading */
}
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contact-container {
      padding-top: 10px; /* Further reduced space for medium screens */
      padding-bottom: 20px;
      height: 80vh;
    }
  }
  
  @media (max-width: 480px) {
    .contact-container {
      padding-top: 10px; /* Even less space for small screens */
      padding-bottom: 20px;
      height: 76vh;
    }
  }

  @media (max-width: 400px) {
    .contact-container {
      padding-top: 10px; /* Even less space for small screens */
      padding-bottom: 20px;
      height: 70vh;
    }
  }
  
  #hubspotForm {
    transform-origin: top center; /* Ensures scaling happens from the top */
    transform: scale(0.8); /* Adjust the scale value as needed */
    width: 100%; /* Set width to 100% */
    margin: 0 auto; /* Center the form after scaling */
    /* Add a negative margin to pull up the container if necessary */
    
    margin-bottom: -120px; /* Adjust this value as needed to remove the extra space */
  }
  
  /* Make form elements smaller */
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select,
  button,
  input[type="submit"] {
    font-size: 13px; /* Further reduced font size for form elements */
    padding: 8px; /* Smaller padding for form elements */
  }
  
  /* Reduce spacing between form fields */
  .contact-form fieldset {
    margin-bottom: 0.5rem; /* Smaller margin between form fields */
  }
  
  /* Make the form submit button smaller */
  .contact-form button[type="submit"],
  .contact-form input[type="submit"] {
    padding: 8px 12px; /* Smaller padding for the button */
    font-size: 13px; /* Smaller font size for the button */
  }
  