.weather-container {
    position: fixed;
    margin: auto;
    top: 14%;
    right:0;
    left:0;
    width: 90%; /* Adjust as necessary */
    height: 73%; /* Adjust as necessary */
    padding: 15px;
    background-color: rgba(241, 241, 241, 0.7);  /* Updated this line */
    border-radius: 10px;
    overflow: hidden;
    z-index: 5000;
    overflow-y: auto;
    
  }

  .title-weather {
    text-align: center; /* Center the text horizontally */
    font-size: 2em; /* Larger text for the title */
    font-weight: bold; /* Make the font bold */
    color: #007bff; /* Color similar to the button for consistency */
    padding: 10px; /* Add some padding around the text */
    margin-top: 20px; /* Space out the title from the top of the container */
    margin-bottom: 20px; /* Space between the title and the content below */
    border-radius: 5px; /* Slightly rounded corners */
    width: 80%; /* Percentage of parent container width */
    margin-left: auto; /* These two margins together center the title */
    margin-right: auto; /* These two margins together center the title */
    z-index: 5001; /* Ensure it's above other elements */
}

form {
    width: 100%;
    max-width: 600px; /* Limits the width of the form */
    margin-top:4%;
    margin-bottom: 4%;
}

input[type="text"] {
    width: calc(100% - 120px); /* Input field takes the remaining space */
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.current-weather, .forecast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    overflow-y: auto;
}

.forecast-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    overflow-y: auto;
}

.forecast-item img {
    max-width: 100px; /* Limits the size of the weather icons */
    margin-bottom: 10px;
}


.close-button-weather {
    position: sticky;
    top: 0;
    left: 100%; /* Stick to the right side */
    margin-left: auto; /* This will help push the button to the far right */
    background-color: transparent;
    border: 1px solid rgb(2, 2, 2);
    font-size: 1.2em;
    cursor: pointer;
    color: black;
    z-index: 5000;
    }

.close-button-weather:hover {
    background-color: rgb(86, 133, 236); /* Darker red on hover */
}

.error-message {
    color: #d32f2f; /* red */
    text-align: center;
    font-size: 1em;
    padding: 10px;
    margin-top: 10px;
}

@media (min-width: 1024px) {
    .forecast-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Creates a responsive grid layout */
        gap: 20px;
    }
}

@media (max-width: 1024px) {
    /* Adjusts the layout for smaller screens like tablets */
    .forecast-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
}

@media (max-width: 768px) {
    /* Adjusts the layout for mobile devices */
    .forecast-container {
        grid-template-columns: 1fr;
    }
}
