.forgot-password-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    width: 300px; /* Adjust width as needed */
    padding: 20px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .forgot-password-container input {
    width: calc(100% - 20px); /* Full width minus padding */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px;
    box-sizing: border-box; /* Include padding in width */
  }
  
  .forgot-password-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .forgot-password-container button:hover,
  .forgot-password-container button:focus {
    background-color: #0056b3; /* Darken button on hover/focus */
  }
  
  /* Optional: Add a little animation to draw attention to the form */
  @keyframes appear {
    from { opacity: 0; transform: translate(-50%, -60%); }
    to { opacity: 1; transform: translate(-50%, -50%); }
  }
  
  .forgot-password-container {
    animation: appear 0.3s ease-out forwards;
  }
  