/* Header.js styles */
.header-container {
    background-color: #333;
    padding: 20px;
    box-sizing: border-box; /* Include padding in total height and width */
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px; /* Adjusted height to include padding */
    z-index: 5000;
}

.header-title {
    
    font-size: 22px;
    font-weight: bold;
    margin-top: 11px;
  }

/*Login displayed name*/
.user-greeting {
    position: absolute;
    top: 12px;  /* This will move the element upwards. Adjust as needed. */
    right: 30px;
    font-size: 15px;
    color: #fff;
    margin-right: 20px;
}

/* logout button functionality */
.logout-button {
    position: absolute;
    top: 25px;
    right: 35px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
  }

 /*                              */
  /* Login Button functionality*/
  /*                            */
  .login-button {
    position: absolute;
    top: 18px;
    right: 35px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
  }
  
  .login-button:hover {
    background-color: #555;
  }
  
  
  
  .logout-button:hover {
    background-color: #555;
  }



@media (max-width: 400px) {
    .header-container {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }
    .header-title, .user-greeting, .logout-button, .login-button {
        position: static;
        margin: 10px 0; /* Add margin for vertical spacing */
        font-size: 14px;
    }
    .user-greeting {
        order: 1; /* Make sure the greeting comes first */
    }
    .logout-button, .login-button {
        order: 2; /* Logout/login button comes last */
    }
    .login-button {
        position: absolute;
        top: 5px;
        right: 1px;


    }
    .logout-button{
        position: absolute;
        top: 11px;
        right: 1px;

    }
}

