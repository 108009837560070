.login-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Fixed position to overlay the content */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* High z-index to make sure it's above other content */
  
  }
  
  .login-form-container {
    background: white;
    padding: 2em;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }



  
  .login-form-container .Login-close-button {
    position:relative;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 1.5rem;
    color: black; /* This will ensure the button is visible against a white background */
    top: -px;
    left:90%;
    cursor: pointer;
  }

  .login-form-container .Login-close-button:hover {
    background-color: #0056b3;
    z-index: 5000;
  }
  .login-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: left;
    text-align: center;
    margin-bottom: 1em;
  }
  
  
  .form-field {
    /* Removed flex-grow and adjusted the width to have a consistent size */
    width: calc(100% - 2.5rem); /* Adjust this value to match the first picture's input width */
    padding: 10px;
    margin-bottom: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 0.5em;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  
  .form-link {
    text-align: center;
    margin-top: 1em;
  }
  
  .form-link button {
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  
  .form-link button:hover {
    color: #0056b3;
  }

  .password-container {
    position: relative; /* Set the password container to position relative */
    display: flex; /* Use flexbox to align input and icon */
    align-items: center; /* Align items vertically */
    justify-content: space-between; 
  }
  
  .password-icon {
    position: absolute;
  right: 10px; /* Horizontal position inside the input */
  top: 40%; /* Start by centering vertically */
  transform: translateY(-50%); /* Shifts the icon up by half its height to truly center it */
  height: 20px;
  width: 20px;
}
  
  .form-field {
    flex-grow: 1; /* Allows the input field to take up available space */
    /* Other existing styles */
    
  }

  .disclaimer-modal {
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    overflow-y: auto; /* Add scroll for long content */
    max-height: 80vh; /* Limit the height on small screens */
  }
  
  .disclaimer-modal h2 {
    color: #333;
    text-align: center;
    margin-bottom: 1em;
  }
  
  .disclaimer-modal p {
    font-size: 1rem;
    line-height: 1.5;
    color: #666;
    margin-bottom: 1.5em;
  }
  
  .disclaimer-modal button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .disclaimer-modal button:hover {
    background-color: #0056b3;
  }
  

  /* Responsive design adjustments */
@media (max-width: 768px) {
  .disclaimer-modal {
    width: 95%;
    padding: 15px;
    max-height: 75vh;
  }
}

@media (max-width: 480px) {
  .disclaimer-modal {
    width: 100%;
    padding: 10px;
    max-height: 70vh;
  }
}