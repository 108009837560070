.about-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the container */
    max-width: 90%;
    max-height: 70vh;
    padding: 15px;
    background-color: rgba(241, 241, 241, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 5000;
    overflow-y: auto;
}

.about-content {
    text-align: justify;
    padding: 0 5vw; /* Use viewport width for padding */
    margin-bottom: 20px;
    line-height: 1.4;
    text-align: left; /* Align text to the left on small screens to avoid spacing issues */
    line-height: 1.4; /* Adjust the line height if necessary */
}

/* Style adjustments for headers and paragraphs */
.about-content h2,
.about-content h3,
.about-content p,
.about-content ul,
.about-content li {
    margin-bottom: 1vh; /* Use viewport height for margin */
    padding: 0;
    line-height: 1.4;
}

/* Style adjustments for unordered lists */
.about-content ul {
    list-style-position: inside;
    padding-left: 0;
}

/* Style adjustments for list items */
.about-content li {
    margin-bottom: 1vh;
}

/* Style adjustments for the close button */
.close-button {
    position: absolute;
    top: 1vh;
    right: 1vw;
    background-color: transparent;
    border: none;
    font-size: 3vw; /* Use viewport width for font size */
    cursor: pointer;
    color: black;
}

.close-button:hover {
    color: #0056b3;
}

/* Style adjustments for the title */
.about-title {
    text-align: center;
    font-size: 5vw; /* Use viewport width for font size */
    color: #007bff;
    margin-bottom: 2vh;
}

/* Media queries for different screen sizes */
@media (max-width: 768px) {
    .about-container {
        max-width: 80%;
    }
    .about-content {
        /* ... your existing styles ... */
        text-align: left; /* Align text to the left on small screens to avoid spacing issues */
        line-height: 1.4; /* Adjust the line height if necessary */
    }
    
    .about-content h2,
    .about-content h3,
    .about-content p,
    .about-content ul,
    .about-content li {
        line-height: 1.2;
    }

    .close-button {
        font-size: 4vw;
    }

    .about-title {
        font-size: 5vw;
    }
    .about-content p, .about-content li {
        /* Reset word-spacing and letter-spacing if previously adjusted */
        word-spacing: normal;
        letter-spacing: normal;
    }
}

@media (max-width: 480px) {
    .about-content {
        padding: 0 4vw;
    }

    .close-button {
        font-size: 8vw;
    }

    .about-title {
        font-size: 8vw;
    }
}
